import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='If you don`t make time for your task, Bug will force you to make time for it.'
          />
          <Divider />
          {/* <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider /> */}
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>Hey Guys! I am Mayak Patel</Text>
                <Text variant='p'>
                  I am working as the CEO of Linearloop Pvt Ltd and have 8 years
                  of experience in evolving web technologies i.e. React JS, Next
                  JS, PHP, DevOps, and Software Architecture. My responsibility
                  includes building software architecture, delivering solutions,
                  and smooth execution of projects.
                </Text>
                <Text variant='p'>
                  My intent is to offer support and solutions to all programmers
                  across the world.
                </Text>
                <Text variant='p'>
                  By being in the industry for more than 8 years, I am a firm
                  believer that technology has empowered the entire world and
                  changed the perspective of human minds as well.
                </Text>
                <Text variant='p'>
                  Here, I have put all my effort to avail reliable solutions for
                  the developers.
                </Text>
                <Text variant='p' style={{ fontWeight: 'bold' }}>
                  My Background
                </Text>

                <Text variant='p'>
                  Basically, I am from Gujarat. If I say I was born with a smart
                  mind, it will be just a myth. Like most of the kids, I was
                  also not much interested in the study. Later on, the
                  challenges of my life had given me some great lessons for a
                  lifetime.
                </Text>
                <Text variant='p'>
                  I really worked hard during my academics and completed my
                  engineering successfully. After completion of the degree, I
                  have joined a startup IT company having 2 employees. At the
                  time, when I left the organization, there were 30 employees.
                  It all had happened because of our passion for evolving
                  technologies.
                </Text>
                <Text variant='p'>
                  Come on guys, get the facts that are unknown to you yet.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Software Architecture'>
                <Card variant='paper'>
                  Solution related to Software Architecture is also available.
                  Feel free to get answers to your queries based on it.
                </Card>
              </Section>
              <Divider />
              <Section title='DevOps'>
                <Card variant='paper'>
                  DevOps is evolving and I know you must need support. Browse
                  your doubt and clear it now.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
